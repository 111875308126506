/* global HTMLElement window document */

import SubscribeSentryLogger from '../../Common/Error/SubscribeSentryLogger';
import { BellroyError } from '../../Common/Error/Types';
import { PortFromElm, PortToElm, promiseFromElmPort } from '../../Common/ElmPorts';
import type { DictString } from '../../../javascript/DictString';
import type { I18n } from '../../../javascript/helpers/I18n';
import { Elm } from '../../../javascript/elm-apps.js';
import Events from '../../Product/Detail/js/Events';
import { EventStream, Event as EventStreamEvent } from '../../EventStream/js/EventStream';
import './style.css';
import '../Detail/js/subscribable-product-form.js';
import EventStreamBroadcast from '../../EventStream/js/Broadcast';

export type RangeQueryParams = {
  sortBy: string | null;
  filterSelections: DictString<string[]>;
};

type ProductRangeElmApp = {
  ports: {
    error: PortFromElm<BellroyError>;
    rangeQueryUpdated: PortFromElm<RangeQueryParams>;
    configurationParsed: PortFromElm<Configuration>;
    onElementIntersectionRatio: PortToElm<[string, number]>;
    mobileFilterVisibilityChanged: PortFromElm<boolean>;
    requestTranslations: PortFromElm<{}>;
    translationsReceiver: PortToElm<DictString<string>>;
    addProductsThroughMiniCart: PortFromElm<{}>;
    rangeAppProductTilesRendered: PortFromElm<{}>;
    pushEvents: PortFromElm<[EventStreamEvent]>;
    broadcastEvent: PortToElm<[EventStreamEvent]>;
  };
};

function siteConfiguration() {
  const $siteConfiguration = document.querySelector('[rel="configuration"]');
  return $siteConfiguration ? JSON.parse($siteConfiguration.innerHTML.trim()) : {};
}

function draftModeWarningExists(): boolean {
  return document.getElementById('DraftModeWarning')?.nodeName?.toUpperCase() === 'DIV';
}

function camelCaseToDashCase(s: string): string {
  return s.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

function camelCaseToDashCaseMapKeys<T>(dict: DictString<T>): DictString<T> {
  return Object.keys(dict).reduce((newDict, key) => {
    newDict[camelCaseToDashCase(key)] = dict[key];
    return newDict;
  }, {});
}

function getInnerHTML(target: Element): string {
  if (
    target.firstElementChild &&
    target.firstElementChild.getAttribute('type') === 'text/template'
  ) {
    target = target.firstElementChild;
  }

  return target.innerHTML.trim().replace(/%5B/gi, '[').replace(/%5D/gi, ']');
}

type RelevantLocation = {
  pathname: string;
  hash: string;
  search: string;
};

function windowLocationFields(): RelevantLocation {
  const { pathname, hash, search } = window.location;
  return { pathname, hash, search };
}

function csrfToken(): string {
  return document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ?? '';
}

export function splitProperty<V>(object: { [key: string]: V }, key: string): V | undefined {
  const v = object[key];
  delete object[key];
  return v;
}

export function rangeQueryParamsFromURLSearchParams(
  searchParams: URLSearchParams,
): RangeQueryParams {
  const sortBy = searchParams.get('sortBy');
  const filterSelections = {};
  for (const [key, value] of searchParams) {
    const [, filterKey] = key.match(/^filter\[(\w*)\]$/) ?? [];
    if (filterKey) {
      filterSelections[filterKey] = value.split(',');
    }
  }
  return {
    sortBy,
    filterSelections,
  };
}

export function urlSearchParamsFromRangeQueryParams(
  queryParams: RangeQueryParams,
): URLSearchParams {
  const searchParams = new URLSearchParams();
  if (queryParams.sortBy) {
    searchParams.append('sortBy', queryParams.sortBy);
  }
  for (const [key, values] of Object.entries(queryParams.filterSelections)) {
    searchParams.append(`filter[${key}]`, values.join(','));
  }
  return searchParams;
}

export type Configuration = {
  applications: {
    audiences: {
      host: string;
    };
    'product-detail': {
      sources: {
        orderabilities: {
          host: string;
        };
        'product-images': {
          host: string;
        };
        products: {
          host: string;
        };
      };
    };
  };
  commerce_information: {
    ab_test_enrolments: { experiment_identifier: string; enrolled_in_variant: string }[];
    currency_identifier: string;
    enrolled_promotional_campaign_identifiers: string[];
    price_list_identifier: string;
    shipping_to: {
      city: string;
      country_code: string;
      region_code: string;
    };
  };
  csrfToken: string;
  data: {
    'default-sku': string;
    'dynamic-dimension-filter-keys': string[];
    'expected-amount-of-product-tiles': number;
    lazy: boolean;
    'max-product-tiles': number;
    'power-score-dimension': string;
    'random-seed': number;
  };
  location: RelevantLocation;
  query: RangeQueryParams;
  template: string;
};

export interface HasElmConfiguration {
  elmConfiguration(): Promise<Configuration>;
}

export default async function (i18nPromise: Promise<I18n>, eventStream: EventStream) {
  class BellroyProductRangeElement extends HTMLElement implements HasElmConfiguration {
    private _configurationPromise!: Promise<Configuration>;
    private app!: ProductRangeElmApp;
    private _events!: Events;

    constructor() {
      super();
    }

    // Returns a Promise containing the configuration as understood by Elm.
    // Primarily for unit testing.
    elmConfiguration(): Promise<Configuration> {
      return this._configurationPromise;
    }

    destroy() {
      // It's currently not possible to "unmount" an Elm application
      let $placeHolder: Element | null = null;
      let { parentElement } = this;

      while (!$placeHolder && parentElement && (parentElement as Node) !== (document as Node)) {
        const rel = parentElement.getAttribute('rel') || '';
        if (rel === 'placeholder') {
          $placeHolder = parentElement;
          break;
        }
        parentElement = parentElement.parentElement;
      }

      if (!$placeHolder) {
        $placeHolder = this;
      }

      this.setAttribute('style', 'display: none;');
    }

    connectedCallback() {
      const innerContent = getInnerHTML(this);
      this.innerHTML = '';

      const node = document.createElement('span');
      node.classList.add('ProductRange');
      this.appendChild(node);

      var updatedDataset = this.dataset;

      if (window.dynamicRendering) {
        updatedDataset['lazy'] = 'false';
      }

      const flags: Configuration = {
        csrfToken: csrfToken(),
        data: {
          edit: draftModeWarningExists(),
          ...camelCaseToDashCaseMapKeys(updatedDataset),
        },
        location: windowLocationFields(),
        query: rangeQueryParamsFromURLSearchParams(new URLSearchParams(location.search)),
        template: innerContent,
        ...siteConfiguration(),
      };

      this.app = Elm.Product.Range.Main.init({
        node,
        flags,
      });

      this._events = new Events(this, siteConfiguration(), () => {}, eventStream);

      SubscribeSentryLogger(this.app.ports.error);
      EventStreamBroadcast(this.app.ports);

      this.app.ports.rangeQueryUpdated.subscribe((rangeQueryParameters) => {
        const url = new URL(window.location.href);
        url.search = urlSearchParamsFromRangeQueryParams(rangeQueryParameters).toString();
        window.history.replaceState(null, '', url.toString());
      });

      this.app.ports.mobileFilterVisibilityChanged.subscribe((mobileFilterOverlayVisible) => {
        document.body.classList.toggle('prevent-body-scroll', mobileFilterOverlayVisible);
      });

      this.app.ports.requestTranslations.subscribe(() => {
        i18nPromise.then((i18n) => {
          this.app.ports.translationsReceiver.send(i18n.translations);
        });
      });

      this.app.ports.addProductsThroughMiniCart.subscribe((request) => {
        const event = new CustomEvent('minicartaddproducts', {
          detail: { request },
          bubbles: true,
        });
        this.dispatchEvent(event);
      });

      this.app.ports.pushEvents.subscribe((events) => {
        events.forEach((event) => {
          this._events.push(event);
        });
      });

      this._configurationPromise = promiseFromElmPort(this.app.ports.configurationParsed);

      this.setAttribute('style', '');

      // We already have an event to track clicked products, so I just want to stop click events from bubbling up to the parent element.
      // If we decide to track click on the product range, we can remove the stopPropagation() method and import the send(event, "productRange") method
      // from `tracking/link-clicket.ts`.
      this.addEventListener('click', (event) => {
        const target = event.target as HTMLElement;
        const anchor = target.closest('a') as HTMLAnchorElement;

        // This fixes elements using bootstrap modals not opening and prevents propagations from child elements that uses it
        // and we are currently tracking.
        if (anchor?.dataset?.toggle) return;
        event.stopPropagation();
      });

      const intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            this.app.ports.onElementIntersectionRatio.send([
              entry.target.id,
              entry.intersectionRatio,
            ]);
          });
        },
        { threshold: [0, 1] },
      );
      this.app.ports.rangeAppProductTilesRendered.subscribe(() => {
        // We need to wait for the next frame to ensure that Elm rendered the product tiles.
        window.requestAnimationFrame(() => {
          const productTiles = this.querySelectorAll('[id^="ProductRangeComponentsLayout_Ref_"]');
          productTiles.forEach((productTile) => {
            intersectionObserver.observe(productTile);
          });
        });
      });
    }
  }

  window.customElements.define('bellroy-product-range', BellroyProductRangeElement);

  return BellroyProductRangeElement;
}
